@import 'Styles/includes';

/**
*
* CardVideoText
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardVideoText {
    $root: &;

    width: 100%;

    &--Horizontal {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media(md) {
            border-radius: 4px;
            overflow: hidden;
            flex-direction: row;
            box-shadow: 0 4px 16px 0 rgba(black, 0.2);
            position: relative;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                background: black;
                width: 50%;
                height: 100%;
                display: block;
            }
        }
    }

    &--Reverse {
        @include media(md) {
            flex-direction: row-reverse;

            &:before {
                left: 50%;
            }
        }
    }

    // &__Tag {
    //     font-size: 1.6rem;
    //     font-weight: 700;
    //     font-weight: normal;
    //     text-transform: uppercase;
    //     margin-bottom: 5px;
    //     line-height: 1.5;
    //     margin-bottom: 8px;
    //     letter-spacing: 0.4px;

    //     @include media(lg) {
    //         font-size: 1.8rem;
    //         margin-bottom: 8px;
    //     }
    // }

    &__Header {
        padding-bottom: 16px;
        margin-bottom: 24px;
        text-align: center;
        border-bottom: 1px solid $gray-20;

        @include media(md) {
            max-width: 75%;
            padding-bottom: 32px;
            margin: 0 auto 48px auto;
        }
    }

    &__Video {
        width: 100%;
        margin-bottom: 36px;
        background-color: $black;

        @include media(md) {
            margin-bottom: 0;
            width: 50%;

            #{$root}--IncreaseVideoSize & {
                width: 65%;
            }
        }

        #{$root}--Wide & {
            width: 100%;
            background-color: transparent;
        }
    }

    &__Content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: white;
        padding: 0 15px;

        #{$root}--Wide & {
            display: none;
        }

        #{$root}--Red & {
            background-color: $red;
        }

        @include media(md) {
            padding: 36px 64px;
            width: 50%;
            align-items: flex-start;
            text-align: left;

            #{$root}--IncreaseVideoSize & {
                width: 35%;
                padding: 36px 46px;
            }
        }
    }

    &__Title {
        font-size: 2.4rem;
        font-weight: bold;
        line-height: 1.33;
        letter-spacing: 0.2px;
        color: $text;
        margin-bottom: 8px;

        #{$root}--Red & {
            color: white;
        }

        #{$root}--Wide & {
            @include media(lg) {
                font-size: 3.6rem;
            }
        }
    }

    &__Text {
        font-size: 1.4rem;
        line-height: 1.5;
        letter-spacing: 0.4px;
        color: $text;
        white-space: break-spaces;
        margin-bottom: 24px;

        #{$root}--Red & {
            color: white;
        }
    }
}
