@import 'Styles/includes';

/**
*
* CardVideo
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardVideo {
    $root: &;

    position: relative;

    &__Button {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        padding-bottom: 56.25%;
        background: none;
        border: none;
        border-radius: 0;
        box-shadow: none;
        cursor: pointer;

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            width: 56px;
            height: 56px;
            background-image: url('#{$publicpath}img/icon-play.svg');
            transition: transform .1s ease;
            transform: translate(-50%, -50%) scale(1);

            #{$root}--ShowMsg & {
                display: none;
            }
        }

        &:hover::after {
            transform: translate(-50%, -50%) scale(1.14);
        }
    }

    &__BackgroundImage {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    &__Video {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        max-width: 100%;

        iframe,
        object,
        embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
